import { useContext } from 'react';
import { Action, ThemeState } from '../models/interface';
import { themeContext } from '../store';

export const useTheme = (): {
  state: ThemeState;
  dispatch: (action: Action) => void;
} => {
  const context = useContext(themeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
